import { IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { format, parseISO } from 'date-fns'
import { forwardRef, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { DialogProps } from '../types'
import { DefaultCard } from '../../Cards'
import { PDFViewer } from '../../PDFViewer'

export const CalculatingDialog = forwardRef<HTMLDivElement, DialogProps>(
  (
    {
      consultation,
      receipt,
      children,
      handleToggleBlocked,
      handleDownloadReceipt,
      handleGenerateReceipt,
      receiptError,
      handleDispatchConsultationAction,
      handleReserveWithImpersonation,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation()

    const { patient, consultationID } = consultation

    const [open, setOpen] = useState(false)

    const handleClick = useCallback(() => {
      handleDownloadReceipt({ consultationID })
      setOpen(true)
    }, [])

    const handleClose = () => setOpen(false)

    return (
      <div {...props} ref={ref}>
        {children({ handleClick })}
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle mt={4}>
            <DefaultCard consultation={consultation} component={Box} />
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() =>
                handleReserveWithImpersonation({
                  patientUUID: patient.patientUUID,
                })
              }
            >
              {t('ReserveWithImpersonation')}
            </Button>
            <Button
              onClick={() =>
                handleToggleBlocked({
                  consultationID,
                  blocked: !consultation.blocked,
                })
              }
            >
              {consultation.blocked ? t('Unblock') : t('Block')}
            </Button>
            <Button
              variant="contained"
              onClick={() =>
                handleGenerateReceipt({
                  consultationID,
                })
              }
            >
              {t('Regenerate Receipt')}
            </Button>
            <Button
              disabled={!consultation.patient.hasLineAccount}
              variant="contained"
              onClick={() =>
                handleDispatchConsultationAction({
                  consultationID,
                  action: 'notify_checkout',
                })
              }
            >
              {t('Notify checkout')}
            </Button>
            <Button
              variant="contained"
              onClick={() =>
                handleDispatchConsultationAction({
                  consultationID,
                  action: 'checkout',
                })
              }
            >
              {t('Checkout without notification')}
            </Button>
          </DialogActions>
          <DialogContent>
            {receiptError && <Alert severity="error">{receiptError}</Alert>}
            {receipt && <PDFViewer b64str={receipt.encodedPDF} />}
            <Typography variant="caption" color="text.secondary">
              {t('CreatedAt')}: {format(parseISO(consultation.createdAt), 'yyyy年MM月dd日HH時mm分')}
            </Typography>
          </DialogContent>
        </Dialog>
      </div>
    )
  }
)
