import React, { ComponentType } from 'react'
import { Box, Chip, GridProps, Grid, Paper, Typography, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Consultation } from '../../types'
import { getDepartmentFromCode } from '../../../common/utils/reservation'
import { birthdayInImperialYear, birthdayToAge } from '../../../common/utils/patient'
import {
  BlockedChip,
  FamilyChip,
  GenderChip,
  InsuranceChip,
  InterviewFormChip,
  InvoiceNumberChip,
  LastConsultationChip,
  NoLineAccountChip,
  SokushinChip,
} from '../Chips'
import { format, parseISO } from 'date-fns'

export type DefaultCardProps = GridProps & {
  onClick?: () => void
  consultation: Consultation
  component?: ComponentType<'div'>
}

export const DefaultCard = React.memo(
  ({ onClick, consultation, component = Paper }: DefaultCardProps) => {
    const { patient, departmentCode } = consultation

    const { t } = useTranslation()

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="streatch"
        onClick={onClick}
        component={component}
        sx={{ p: 2 }}
        columns={10}
      >
        <Grid
          item
          xs={2}
          direction="column"
          spacing={2}
          sx={{
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <Stack>
            <Box>
              <Chip label={`${t('Reception Number')} ${consultation.receptionNumber}`} />
            </Box>
            <Box>
              <Typography component="span">
                {getDepartmentFromCode(departmentCode).displayName}
              </Typography>
            </Box>
            {consultation.reservation && (
              <Box>
                <Typography component="span">
                  {t('Reservation Time')}{' '}
                  {format(parseISO(consultation.reservation.reservationDatetime), 'HH:mm')}
                </Typography>
              </Box>
            )}
          </Stack>
        </Grid>
        <Grid item xs container>
          <Grid
            xs={12}
            item
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid xs item>
              <Stack direction="row" spacing={2}>
                <Typography fontWeight="bold">ID: {patient.patientID}</Typography>
                <GenderChip gender={consultation.patient.gender} />
              </Stack>
            </Grid>
            <Grid
              xs
              item
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              <Grid item sx={{ pr: 1 }}>
                <Typography variant="caption" color="text.secondary">
                  {t('UpdatedAt')}: {format(parseISO(consultation.updatedAt), 'HH時mm分')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} item container direction="row" alignItems="flex-end">
            <Grid xs={4} item>
              <Typography variant="caption" fontWeight="bold">
                {patient.fullNameKana}
              </Typography>
              <Typography variant="h3" fontWeight="bold">
                {patient.fullName}
              </Typography>
            </Grid>
            <Grid xs={4} item>
              <Typography fontSize="h4">{birthdayToAge(patient.birthday)} 歳</Typography>
              <Typography fontSize="h4">{birthdayInImperialYear(patient.birthday)}</Typography>
            </Grid>
            <Grid xs={4} item>
              <Grid container spacing={1}>
                {!patient.hasLineAccount && (
                  <Grid item>
                    <NoLineAccountChip />
                  </Grid>
                )}
                {patient.hasSokushin && (
                  <Grid item>
                    <SokushinChip />
                  </Grid>
                )}
                <Grid item>
                  <LastConsultationChip lastConsultation={consultation.lastConsultation} />
                </Grid>
                {consultation.interviewForm && (
                  <Grid item>
                    <InterviewFormChip />
                  </Grid>
                )}
                {consultation.blocked && (
                  <Grid item>
                    <BlockedChip />
                  </Grid>
                )}
                {consultation.patient.parentPatientUUID && (
                  <Grid item>
                    <FamilyChip />
                  </Grid>
                )}
                {consultation.insuranceCombinationNumber && (
                  <Grid item>
                    <InsuranceChip
                      insuranceCombinationNumber={consultation.insuranceCombinationNumber}
                    />
                  </Grid>
                )}
                {consultation.invoiceNumber && (
                  <Grid item>
                    <InvoiceNumberChip invoiceNumber={consultation.invoiceNumber} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
)
